.abc {
  /* color: blue; */
}

.bg-half-size {
  background-size: 70%; /* 背景画像のサイズを半分に設定 */
}

.subpage-bg-half-size {
  background-size: 100%; /* 背景画像のサイズを100％に設定 */
}

.hidden-scrollbar {
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

/* 凡例全体のスタイル */
.recharts-default-legend {
  width: 100%; /* 例: 横幅を100%に設定 */
  font-size: 10px; /* 例: フォントサイズを16pxに設定 */
}

/* 個々の凡例アイテムのスタイル */
.recharts-legend-item {
  margin-right: 20px; /* 例: 右のマージンを20pxに設定 */
}

/* CSSファイルの例 */
.recharts-text.recharts-cartesian-axis-tick-value {
  font-size: 10px; /* フォントサイズを変更 */
  /* 他のスタイル属性 */
}

.recharts-rectangle {
  /* バーのスタイル変更 */
}
